import * as React from 'react';
import { Box } from '@latitude/box';
import styled from 'styled-components';
import { COLOR, PADDING, MARGIN } from '@latitude/core/utils/constants';
import OfferTileItem from './OfferTileItem';
import OfferData from './offers.json';

const OfferTilesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`;

const OfferTiles = () => {
  return (
    <Box
      paddingBottom={PADDING.P32}
      paddingTop={PADDING.P32}
      backgroundColor={COLOR.GREY6}
    >
      <Box marginLeft={MARGIN.MAUTO} marginRight={MARGIN.MAUTO} isResponsive>
        <OfferTilesWrapper>
          {OfferData.map(offer => {
            return (
              <OfferTileItem
                id={offer.id}
                key={offer.id}
                name={offer.name}
                offerDetails={offer.offerDetails}
                url={offer.url}
                logo={offer.logo}
                ctaHref={offer.ctaHref}
                backgroundImage={offer.backgroundImage}
                trackId={offer.trackId}
                offerDetailsExtra={offer.offerDetailsExtra}
                ctaButton={offer.ctaButton}
                offerDetailsSub={offer.offerDetailsSub}
                offerDetailsPadding={offer.offerDetailsPadding}
                offerDetailsSubPadding={offer.offerDetailsSubPadding}
              />
            );
          })}
        </OfferTilesWrapper>
      </Box>
    </Box>
  );
};

export default OfferTiles;
