import React from 'react';
import styled from 'styled-components';
import { Link } from '@latitude/link';
import {
  BUTTON_STYLE,
  ALIGN,
  COLOR,
  MARGIN,
  FONT_WEIGHT
} from '@latitude/core/utils/constants';
import { Text } from '@latitude/text';
import parse from 'html-react-parser';

/* --- start: hype phase offer data ---*/
import kathmanduBGImg from './images/background/1kathmandu.jpg';
import torpedo7BGImg from './images/background/2torpedo.png';
import hallensteinsBGImg from './images/background/3hallensteins.jpg';
import nzsaleBGImg from './images/background/4nzsale.jpg';
import bakersBGImg from './images/background/7bakers.png';
import onceitBGImg from './images/background/8onceit.jpg';
import themarketBGImg from './images/background/9themarket.png';
import rodgunnBGImg from './images/background/10rodgunn.png';
import nikebritomartBGImg from './images/background/11nike.jpg';
import zebranoBGImg from './images/background/12zebrano.png';
import roopdarshanBGImg from './images/background/13roopdarshan.jpg';
import canterburyBGImg from './images/background/14canterbury.jpg';
import hyperideBGImg from './images/background/15hyperide.png';
import augestineBGImg from './images/background/16augestine.jpg';
import priorBGImg from './images/background/17prior.jpg';
import luggageBGImg from './images/background/18luggage.jpg';
import homewareonlineBGImg from './images/background/19homewareonline.jpg';
import ccBGImg from './images/background/20cc.jpg';

const BackgroundImages = {
  kathmanduBGImg,
  torpedo7BGImg,
  hallensteinsBGImg,
  nzsaleBGImg,
  bakersBGImg,
  onceitBGImg,
  themarketBGImg,
  rodgunnBGImg,
  nikebritomartBGImg,
  zebranoBGImg,
  roopdarshanBGImg,
  canterburyBGImg,
  hyperideBGImg,
  augestineBGImg,
  priorBGImg,
  luggageBGImg,
  homewareonlineBGImg,
  ccBGImg
};

function getBackgroundImgByKey (key) {
  return BackgroundImages[key];
}

/* --- end: hype phase offer data ---- */

const EventCard = styled.div`
  background-color: ${COLOR.WHITE};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  margin: ${MARGIN.M24} ${MARGIN.M8};
  max-width: 295px;
  flex: 1 1 33%;

  &:hover {
    text-shadow: none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.34);
  }
`;

const StyledCardImageContainer = styled.div`
  background-image: url(${({ backgroundImage }) => {
    return backgroundImage ? `${backgroundImage}` : 'none';
  }});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 196px;
  width: 295px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
`;

const StyledLink = styled(Link)`
  margin: 25px auto 10px;
  width: 180px;
  position: absolute;
  left: 50%;
  margin-left: -90px;
  top: 60%;

  &:active {
    top: 60% !important;
  }
`;

const StyledCardBody = styled.div`
  padding: 5px 20px 10px;
  text-align: center;
  height: 220px;
  position: relative;
`;

const OfferDetailsExtra = styled(Text)`
  > i {
    visibility: ${props => {
      return props.offerDetailsExtra ? 'visible' : 'hidden';
    }};
  }
`;
const OfferDetailsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100px;
`;

const OfferDetailsText = styled(Text)`
  padding: 16px
    ${props => {
      return props.offerDetailsPadding || '8px';
    }}
    0px;
  > sup {
    font-size: 10px;
    font-weight: normal;
    top: -10px;
  }
`;

const OfferDetailsSubText = styled(Text)`
  padding: 2px
    ${props => {
      return props.offerDetailsSubPadding || '0px';
    }};
`;

const OfferTileItem = offer => {
  const {
    id,
    name,
    offerDetails,
    url,
    backgroundImage,
    trackId,
    offerDetailsExtra,
    offerDetailsSub,
    ctaHref,
    ctaButton,
    offerDetailsPadding,
    offerDetailsSubPadding
  } = offer;

  return (
    <EventCard href={url} key={id}>
      <StyledCardImageContainer
        backgroundImage={getBackgroundImgByKey(backgroundImage)}
      />
      <StyledCardBody>
        <OfferDetailsExtra
          align={ALIGN.CENTER}
          color={COLOR.GREY}
          fontWeight={FONT_WEIGHT.NORMAL}
          offerDetailsExtra={offerDetailsExtra}
        >
          {/* only used for adding spacing if no extra info provided */}
          <i>{offerDetailsExtra || 'hidden'}</i>
        </OfferDetailsExtra>
        <OfferDetailsWrapper>
          <OfferDetailsText
            align={ALIGN.CENTER}
            color={COLOR.BLACK}
            fontWeight={FONT_WEIGHT.MEDIUM}
            fontSize="18px"
            offerDetailsPadding={offerDetailsPadding}
            offerId={id}
          >
            {parse(offerDetails)}
          </OfferDetailsText>
          {offerDetailsSub && (
            <OfferDetailsSubText
              align={ALIGN.CENTER}
              color={COLOR.BLACK}
              fontWeight={FONT_WEIGHT.NORMAL}
              fontSize="15px"
              offerDetailsSubPadding={offerDetailsSubPadding}
            >
              {parse(offerDetailsSub)}
            </OfferDetailsSubText>
          )}
        </OfferDetailsWrapper>
        <StyledLink
          trackId={trackId}
          trackEventData={{
            location: name
          }}
          href={ctaHref}
          button={BUTTON_STYLE.TERTIARY}
        >
          {ctaButton}
        </StyledLink>
      </StyledCardBody>
    </EventCard>
  );
};

export default OfferTileItem;
